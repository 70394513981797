import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IGenericResponse, Backend } from 'src/app/shared/models/GenericResponse'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  constructor(private http: HttpClient) {

  }

  saveZone(data): Observable<IGenericResponse> {
    
    const url = `${Backend.config().api_Url}/zones/save`
    return this.http.post<IGenericResponse>(url, data);
  }

  removeZone(data): Observable<IGenericResponse> {
    
    const url = `${Backend.config().api_Url}/zones/remove`;
    return this.http.post<IGenericResponse>(url, data);
  }
}
