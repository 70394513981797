import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, tap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { UserRole } from './auth.roles';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router) {}


  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    
    return this.authService.user$.pipe(
      take(1),
      map((currentUser) => {
        //console.log('User: ', currentUser)

        if (currentUser) {
          if (route.data && route.data.roles) {
            if (route.data.roles.includes(currentUser.role)) {
              return true;
            } else {
              switch(currentUser.role){
                case UserRole.Admin:
                  this.router.navigate(['/admin']);
                  break;
                 case UserRole.Vendor:
                  this.router.navigate(['/vendor']);
                  break;
                case UserRole.Customer:
                  this.router.navigate(['/client']);
                  break;
                default:
                  this.router.navigate(['/unauthorized']);
              }
              
              return false;
            }
          } else {
            return true;
          }
        } else {
          this.router.navigate(['/user/login']);
          return false;
        }

      })
    );

    
  }

  

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    return this.authService.user$.pipe(
      take(1),
      map((currentUser) => {
        //console.log('User: ', currentUser)

        if (currentUser && currentUser.uid !==null) {
          if (route.data && route.data.roles) {
            if (route.data.roles.includes(currentUser.role)) {
              return true;
            } else {
              /* this.router.navigate(['/unauthorized']);
              return false; */
              switch(currentUser.role){
                case UserRole.Admin:
                  this.router.navigate(['/admin']);
                  break;
                 case UserRole.Vendor:
                  this.router.navigate(['/vendor']);
                  break;
                case UserRole.Customer:
                  this.router.navigate(['/client']);
                  break;
                default:
                  this.router.navigate(['/unauthorized']);
              }
              
              return false;
            }
          } else {
            return true;
          }
        } else {
          this.router.navigate(['/user/login']);
          return false;
        }

      })
    );

  }


  /*async canActivateChild1(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let currentUser = await this.authService.getUser();

    if (currentUser) {
      if (route.data && route.data.roles) {
        if (route.data.roles.includes(currentUser.role)) {
          return true;
        } else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/user/login']);
      return false;
    }
  }*/

  /*async canActivate1(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let currentUser = await this.authService.getUser();

    if (currentUser && currentUser.uid !==null) {
      if (route.data && route.data.roles) {
        if (route.data.roles.includes(currentUser.role)) {
          return true;
        } else {
          this.router.navigate(['/unauthorized']);
          return false;
        }
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/user/login']);
      return false;
    }
  }*/
}
