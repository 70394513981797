import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IGenericResponse, Backend } from 'src/app/shared/models/GenericResponse'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  private stripe: StripeService;
  private paypal: PaypalService;

  constructor(private http: HttpClient) {
    this.stripe = new StripeService(http);
    this.paypal = new PaypalService(http);
  }

  public get Stripe(){
    return this.stripe;
  }

  public get Paypal(){
    return this.paypal;
  }

  getAccounts(id): Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/payments/accounts/${id}`
    return this.http.get<IGenericResponse>(url);
  }

  //save account info for location
  saveAccount(data): Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/locations/gateways/save`
    return this.http.post<IGenericResponse>(url, data);
  }
  
}

class PaypalService {
  constructor(private http: HttpClient) {

  }
  saveAccount(data): Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/payments/paypal/add`
    return this.http.post<IGenericResponse>(url, data);
  }
}


class StripeService {
  constructor(private http: HttpClient) {

  }

  createAccount(data): Observable<IGenericResponse> {
    
    const url = `${Backend.config().api_Url}/payments/stripe/add`
    return this.http.post<IGenericResponse>(url, data);
  }

}