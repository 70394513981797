import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IGenericResponse, Backend } from 'src/app/shared/models/GenericResponse'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebpagesService {


  constructor(private http: HttpClient) {

  }



  getCompanies(): Observable<IGenericResponse> {
    
    const url = `${Backend.config().api_Url}/companies/clients`
    return this.http.get<IGenericResponse>(url);
  }

  getPagesConfig(id): Observable<IGenericResponse>{
    const url = `${Backend.config().api_Url}/pages/company/config/${id}`
    return this.http.get<IGenericResponse>(url);
  }

}
