// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl : "https://1.envato.market/6NV1b",
  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  apiUrl: 'https://api.coloredstrategies.com',
  defaultMenuType: 'menu-sub-hidden',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: false,  
  //apiurl:'http://localhost:2020',
  apiurl: 'https://api.rolosys.com',
  clientSelector: true,
  /*
  Color Options:
  'light.blueyale', 'light.blueolympic', 'light.bluenavy', 'light.greenmoss', 'light.greenlime', 'light.yellowgranola', 'light.greysteel', 'light.orangecarrot', 'light.redruby', 'light.purplemonster'
  'dark.blueyale', 'dark.blueolympic', 'dark.bluenavy', 'dark.greenmoss', 'dark.greenlime', 'dark.yellowgranola', 'dark.greysteel', 'dark.orangecarrot', 'dark.redruby', 'dark.purplemonster'
  */
  defaultColor: 'light.blueyale',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    apiKey: "AIzaSyCb7iGnUMFOPy_f9SizZ6DOFNAajF_-Bao",
    authDomain: "roloapp-257a3.firebaseapp.com",
    databaseURL: "https://roloapp-257a3.firebaseio.com",
    projectId: "roloapp-257a3",
    storageBucket: "roloapp-257a3.appspot.com",
    messagingSenderId: "839669549952",
    appId: "1:839669549952:web:e701026bcd6b7d232e8f65",
    measurementId: "G-SYLZV7GCRS"
  }
};
