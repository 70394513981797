import {
  Component,
  OnInit,
  Renderer2,
  OnDestroy,
  HostListener,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import { environment } from 'src/environments/environment';
import { IPageInfo, PagesService } from 'src/app/shared/services/pages.service'
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from "@angular/router";
import { LangService } from "src/app/shared/lang.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit, OnDestroy {

  /* @ViewChild('gotoTop') gotoBtn: ElementRef; */
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  page: any = null;
  userLang: string = 'es-ES'
  availiableLng = ['en', 'es'];
  selectionLng = {
    en: true,
    es: false
  }
  languages = [];


  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef,
    private scrollToService: ScrollToService,
    private pageSrv: PagesService,
    private router: ActivatedRoute,
    private route: Router,
    private lang: LangService,
    private titleSrv: Title) {

    try {
      this.userLang = navigator.language || navigator['userLanguage'];
    } catch (ex) {

    }

  }

  showMobileMenu = false;

  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;



  custom_style: any = {};

  mainSlideSettings = {
    type: "carousel",
    gap: 30,
    perView: 2.5,
    hideNav: true,
    peek: { before: 10, after: 10 },
    breakpoints: {
      "600": { perView: 1.5 },
      "992": { perView: 2.5 },
      "1200": { perView: 3.5 },
    },
  };

  slideItems = [
    {
      icon: "iconsminds-mouse-3",
      title: "Right Click Menu",
      detail:
        "Increases overall usability of the project by providing additional actions menu.",
    },
    {
      icon: "iconsminds-electric-guitar",
      title: "Video Player",
      detail:
        "Carefully themed multimedia players powered by Video.js library with Youtube support.",
    },
    {
      icon: "iconsminds-keyboard",
      title: "Keyboard Shortcuts",
      detail:
        "Easily configurable keyboard shortcuts plugin that highly improves user experience.",
    },
    {
      icon: "iconsminds-three-arrow-fork ",
      title: "Two Panels Menu",
      detail:
        "Three states two panels icon menu that looks good, auto resizes and does the job well.",
    },
    {
      icon: "iconsminds-deer",
      title: "Icons Mind",
      detail:
        "1040 icons in 53 different categories, designed pixel perfect and ready for your project.",
    },
    {
      icon: "iconsminds-palette",
      title: "20 Color Schemes",
      detail:
        "Colors, icons and design harmony that creates excellent themes to cover entire project.",
    },
    {
      icon: "iconsminds-air-balloon-1",
      title: "3 Applications",
      detail:
        "Applications that mostly made of components are the way to get started to create something similar.",
    },
    {
      icon: "iconsminds-resize",
      title: "Extra Responsive",
      detail:
        "Custom Bootstrap 4 xxs & xxl classes delivers better experiences for smaller and larger screens.",
    },
  ];

  

  

  applications = [
    {
      title: "Survey",
      path: `${this.adminRoot}/applications/survey`,
      img: "/assets/img/landing-page/applications/survey.jpg",
    },
    {
      title: "Chat",
      path: `${this.adminRoot}/applications/chat`,
      img: "/assets/img/landing-page/applications/chat.jpg",
    },
    {
      title: "Todo",
      path: `${this.adminRoot}/applications/todo`,
      img: "/assets/img/landing-page/applications/todo.jpg",
    },
  ];

  themes = [
    { title: "Navy Blue", class: "bluenavy" },
    { title: "Olympic Blue", class: "blueolympic" },
    { title: "Yale Blue", class: "blueyale" },
    { title: "Moss Green", class: "greenmoss" },
    { title: "Lime Green", class: "greenlime" },
    { title: "Carrot Orange", class: "carrotorange" },
    { title: "Ruby Red", class: "rubyred" },
    { title: "Monster Purple", class: "monsterpurple" },
    { title: "Steel Grey", class: "steelgrey" },
    { title: "Granola Yellow", class: "granolayellow" },
  ];

  ngOnInit() {
    this.renderer.addClass(document.body, "no-footer");
    this.userLang = this.lang.detectLanguage(this.userLang);
    this.languages = this.lang.supportedLanguages;
    console.log('LANGUAGEs: ', this.languages)

    this.availiableLng.map(m=>{
      this.selectionLng[m] = m == this.userLang;
    })

    window.onscroll = ()=>{
      const limitTop = 100;
      const topButton = document.getElementById("gotoTop");
      //console.log('TOP BUTTON', this.gotoBtn)
      if (document.body.scrollTop > limitTop || document.documentElement.scrollTop > limitTop) {
        topButton.style.display = "block";
      } else {
        topButton.style.display = "none";
      }
    };

    

    this.router.children.forEach(child => {
      child.params.subscribe((params) => {
        //console.log('PARAMS3: ', params)
        const { id, view } = params;
        const data: IPageInfo = { id, view, root: '/home' };

        if(view==null){
          //this.route.navigateByUrl(`${data.root}/${id}/home`);
          location.href = `${data.root}/${id}/home`;
        }else{
          

          this.pageSrv.getPage(data)
            .subscribe(getPageResult => {
              if (getPageResult.success) {
                this.page = getPageResult.data;
                this.page.url = data;
                this.page.lng = this.userLang;

                this.titleSrv.setTitle(this.page.page.name[this.userLang])
                this.favIcon.href = this.page.header.company_icon_url;
  
                console.log('THE PAGE: ', this.page);
                this.processModel();
                //console.log('serving to child')
                this.pageSrv.setPage(this.page);
              }
            })
        }
        

      });
    });

    

  }

  gotoTop(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera 
  }

  private processModel(){
    const  black_color= "#000000"
    const buttons_style = {
      'background-color': this.page.header.colors.primary,
      color: this.page.header.colors.secondary,
      text: {
        'color': this.page.header.colors.secondary
      }
    };

    this.custom_style = {      
      body: {
        'background-color': this.page.header.colors.background
      },
      pleca: {
        'background-color': this.page.header.colors.primary,
        text: {
          'color': this.page.header.colors.secondary
        }        
      },
      carousel:{
        text: {
          'color': black_color
        }
      },
      option_group: {
        'background-color': this.page.header.colors.primary,
        text: {
          'color': this.page.header.colors.secondary
        },
        title:{
          'color': this.page.header.colors.primary
        },
        tagline:{
          'color': this.page.header.colors.secondary_text
        }    
      },
      service_group: {
        title:{
          color: black_color
        },
        tagline: {
          color: black_color
        }
      },
      posts:{
        title:{
          color: black_color,
          'font-weight': 'bold',
          'font-size': '25px'
        },
        description:{
          color: this.page.header.colors.secondary_text
        }

      },
      btn_top: {
        'background-color': this.page.header.colors.primary,
        text: {
          'color': this.page.header.colors.secondary
        }
      },
      back_button: buttons_style,
      language: buttons_style
    }    
    this.page.styles = this.custom_style;
  }

  toggleLang(lng){
    this.page.lng = lng ;
    this.lang.language = this.languages.filter(f=>f.shorthand==lng)[0].code;
    this.pageSrv.setPage(this.page);
    const title = this.page.page.name[lng];
    //console.log('TITLE: ', title);
    this.titleSrv.setTitle(title);
    this.toggleMenu()
  }

  toggleMenu(){
    this.showMobileMenu=!this.showMobileMenu; 
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, "no-footer");
  }

  @HostListener("window:resize", ["$event"])

  onResize(event) {
    /*const homeRect = this.elRef.nativeElement
      .querySelector(".home-row")
      .getBoundingClientRect();

    const homeSection = this.elRef.nativeElement.querySelector(
      ".landing-page .section.home"
    );
    homeSection.style.backgroundPositionX = homeRect.x - 580 + "px";

    const footerSection = this.elRef.nativeElement.querySelector(
      ".landing-page .section.footer"
    );
    footerSection.style.backgroundPositionX = event.target.innerWidth - homeRect.x - 2000 + "px";

    if (event.target.innerWidth >= 992) {
      this.renderer.removeClass(
        this.elRef.nativeElement.querySelector(".landing-page"),
        "show-mobile-menu"
      );
    }*/
  }

  @HostListener("window:click", ["$event"])
  onClick(event) {
    this.showMobileMenu = false;
  }

  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    this.showMobileMenu = false;
  }

  scrollTo(target) {
    const config: ScrollToConfigOptions = {
      target,
      offset: -150
    };

    this.scrollToService.scrollTo(config);
  }
}
