import { Injectable } from '@angular/core';
import { WebpagesService } from './webpages.service';
import { StructService } from './struct.service'
import { PagesService } from './pages.service';
import { AccessesService } from './accesses.service'
import { LocationsService } from './locations.service'
import { PaymentsService } from './payments.service'
import { ZonesService } from './zones.service'
import { v4 as uuidv4 } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private webPages: WebpagesService,
    private pagesSrv: PagesService,
    private structSrv: StructService,
    private accessesSrv : AccessesService,
    private locationSrv: LocationsService,
    private paymentsSrv: PaymentsService,
    private zonesSrv: ZonesService
    ) { }


  public get Locations(){
    return this.locationSrv;
  }

  public get WebPages(){
    return this.webPages;
  }

  public get Struct(){
    return this.structSrv
  }

  public get Pages(){
    return this.pagesSrv;
  }

  public get Payments(){
    return this.paymentsSrv;
  }

  public get Accesses(){
    return this.accessesSrv;
  }

  public get Zones(){
    return this.zonesSrv;
  }

  public get UuidV4(){
    return uuidv4();
  }
  public get UuidV4Short(){
    return uuidv4().split('-')[0];
  }

}
