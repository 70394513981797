<div [ngClass]="{'landing-page': true,'show-mobile-menu': showMobileMenu}" *ngIf="page; else loading">
    <div class="mobile-menu" (click)="$event.stopPropagation()">
        <a (click)="scrollTo('#home')" href="javascript:;" class="logo-mobile">
            <span class="rolo-custom-icon" style="background: url('{{page.header.company_logo_url}}')"></span>
        </a>
        <ul class="navbar-nav">            
            <li class="nav-item"><a [routerLink]="['/subscription/en/pro']" href="javascript:;">{{ 'webpages.payment_methods' | translate}}</a></li>
            <li class="nav-item"><a [routerLink]="['/subscription/en/pro']" href="javascript:;">{{ 'webpages.contact' | translate }}</a></li>
            <!-- <li class="nav-item"><a [routerLink]="['/subscription/en/pro']" href="javascript:;">SUBSCRIPTION</a></li> -->
            <li class="nav-item">
                <div class="separator"></div>
            </li>
            <li class="nav-item text-center row">
                <div class="btn-group col-12" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-lng" [ngClass]="{'active': page.lng=='es'}" [ngStyle]="page.styles.language" (click)="toggleLang('es')">
                        <span *ngIf="page.lng=='es'" class="glyph-icon simple-icon-check"></span>
                        Español
                    </button>
                    <button type="button" class="btn btn-lng" [ngClass]="{'active': page.lng=='en'}" [ngStyle]="page.styles.language" (click)="toggleLang('en')">
                        <span *ngIf="page.lng=='en'" class="glyph-icon simple-icon-check"></span>
                        English
                    </button>                    
                  </div>
            </li>
        </ul>
    </div>

    <div id="top_page" class="main-container">
        <ngx-headroom class="landing-page-nav">
            <nav>
                <div class="container d-flex align-items-center justify-content-between">
                    <a class="navbar-logo pull-left" (click)="scrollTo('#home')" href="javascript:;">
                        <span class="rolo-custom-icon" style="background: url('{{page.header.company_logo_url}}')"></span>
                      <!-- <span class="white"></span> 
                        <span class="dark"></span>-->  
                    </a>
                    
                    <!-- <ul class="navbar-nav d-none d-lg-flex flex-row">
                        <li class="nav-item"><a (click)="scrollTo('#features')" href="javascript:;">FEATURES</a></li>
                        <li class="nav-item"><a (click)="scrollTo('#layouts')" href="javascript:;">LAYOUTS</a></li>
                        <li class="nav-item"><a (click)="scrollTo('#components')" href="javascript:;">COMPONENTS</a>
                        </li>
                        <li class="nav-item"><a (click)="scrollTo('#apps')" href="javascript:;">APPS</a></li>
                        <li class="nav-item"><a (click)="scrollTo('#themes')" href="javascript:;">THEMES</a></li>
                        <li class="nav-item"><a [routerLink]="['/subscription/en/pro']">SUBSCRIPTION</a></li>
                        <li class="nav-item pl-4">
                            <a class="btn btn-outline-semi-light btn-sm pr-4 pl-4" target="_blank"
                                [href]="buyUrl">BUY</a>
                        </li>
                    </ul> -->
                    <span class="mobile-menu-button" style="color: #000;" (click)="toggleMenu(); $event.stopPropagation();">
                        <i class="simple-icon-menu"></i>
                    </span>
                </div>
            </nav>
        </ngx-headroom>


        <div class="content-container" id="home">
            <router-outlet></router-outlet>
        </div>
        <button type="button" id="gotoTop" class="btn btn-goto-top" [ngStyle]="page.styles.btn_top" (click)="gotoTop()">
            <i class="fas fa-chevron-up"></i>
        </button>
    </div>
</div>

<ng-template #loading>
    <div class="loading"></div>
  </ng-template>