import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { environment } from 'src/environments/environment';

import { HomeComponent } from './home/home.component';
import { AuthGuard } from '../shared/auth.guard';
import { UserRole } from '../shared/auth.roles';

import { SuccessComponent } from './subscription/success/success.component'

const adminRoot = environment.adminRoot.substr(1); // path cannot start with a slash

let routes: Routes = [
  {
    path: '',
    redirectTo: adminRoot,
    //component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    loadChildren: ()=> import('./home/home.module').then((m)=> m.HomeModule)
  },
  {
    path: 'subscription/:ln/:id',    
    loadChildren: () => import('./subscription/subscription.module').then((m) => m.SubscriptionModule )
  }
  ,{
    path: 'subscribe/success',
    component: SuccessComponent,
    pathMatch: 'full'
  },
  {
    path: 'delivery',
    loadChildren: ()=> import('./delivery/delivery.module').then(m=> m.DeliveryModule)
  },
  {
    path: adminRoot,
    loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    //data: { roles: [UserRole.Admin, UserRole.Vendor, UserRole.Customer] },
    data: { roles: [] },
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard]
  },
  {
    path: 'client',
    loadChildren: ()=> import('./client/client.module').then((m)=> m.ClientModule)
  },
  {
    path: 'admin',
    loadChildren: ()=> import('./admin/admin.module').then((m)=> m.AdminModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule)    
  },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: '**', redirectTo: '/error' },
];

/* if (!environment.isAuthGuardActive) {
  routes = [
    {
      path: '',
      component: HomeComponent,
      pathMatch: 'full',
    },
    {
      path: 'subscription/:id',
      loadChildren: () => import('./subscription/subscription.module').then((m) => m.SubscriptionModule )
    },
    {
      path: 'app',
      loadChildren: () => import('./app/app.module').then((m) => m.AppModule),
    },
    {
      path: 'user',
      loadChildren: () => import('./subscription/subscription.module').then((m) => m.SubscriptionModule )
      //loadChildren: () =>
      //import('./user/user.module').then((m) => m.UserModule),
    },
    { path: 'error', component: ErrorComponent },
    { path: '**', redirectTo: '/error' },
  ];
} */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ViewRoutingModule {}
