import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore'
import { from, Observable, of } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';

import { getUserRole } from 'src/app/utils/util';
import { User } from 'src/app/shared/models/FireBaseModels'
import { UserRole } from 'src/app/shared/auth.roles'

export interface ISignInCredentials {
  email: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
  customerId?: string;
  role: UserRole
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  public user$: Observable<User>;

  constructor(
    private auth: AngularFireAuth,
    private afs: AngularFirestore
  ) {

    this.user$ = this.auth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        }
        return of(null);
      })
    );


  }

  signIn(credentials: ISignInCredentials) {
    return this.auth
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(({ user }) => {
        return user;
      });
  }

  signOut() {
    localStorage.removeItem('x-udata-rolo');
    return from(this.auth.signOut());
  }

  register(credentials: ICreateCredentials) {
    return this.auth
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then(async ({ user }) => {
        var userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
        user.updateProfile({
          displayName: credentials.displayName
        });
        this.auth.updateCurrentUser(user);

        let data: User = {
          uid: user.uid,
          email: user.email,
          emailVerified: user.emailVerified,
          customerId: credentials.customerId,
          role: credentials.role
        };

        userRef.set(data, { merge: true });
        return user;
      });
  }

  sendPasswordEmail(email) {
    return this.auth.sendPasswordResetEmail(email).then(() => {
      return true;
    });
  }

  resetPassword(credentials: IPasswordReset) {
    return this.auth
      .confirmPasswordReset(credentials.code, credentials.newPassword)
      .then((data) => {
        return data;
      });
  }

  async getUser() {

    let _resolve = null;
    var promise = new Promise<any>(( resolve, reject)=>{
      _resolve = resolve;
    })

    const u = await this.auth.currentUser;
    //console.log('The user: ', u );
    let _data = { ...u, role: 3 };

    if (u != null) {
      const result = this.afs.doc<User>(`users/${u.uid}`).ref.get().then((user) => {
        var  settings = user.data();
        //console.log('DATA:', settings)
        _data.displayName = _data.displayName || _data.email;
        _data.role = settings.role;
        if(settings.displayName!=null){
          _data.displayName = settings.displayName;
        }
        //console.log('The user: ', settings);
        localStorage.setItem('x-udata-rolo', JSON.stringify({
          uid: _data.uid,
          email: _data.email,
          role: _data.role,
          cuid: settings.customerId
        }));
        _resolve(_data)  
      })

    }else{
      _resolve({})
    }

    return promise;

    

  }
}
