import { Injectable, Inject, Optional } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, from } from 'rxjs';
import { LocalStoreService } from './local-store.service'
import { v4 as uuidv4 } from 'uuid'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        @Optional() @Inject('serverUrl') protected serverUrl: string,         
        private ls: LocalStoreService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        return from(this.handle(req, next));

    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        const serverReq = !this.serverUrl ? req : req.clone({
            url: `${this.serverUrl}${req.url}`
          });
      
          // Get the auth header from the service.
          let authHeader = this.ls.getItem(this.ls.APP_TOKEN);
          if(authHeader==null) { 
              authHeader = uuidv4();
              
              this.ls.setItem(this.ls.APP_TOKEN, authHeader )
          }

          //console.log('APP TOKEN: ', authHeader);
      
          // Clone the request to add the new header
         /*  const authReq = serverReq.clone({
            headers: req.headers.set('x-app-id', authHeader)
          }); */
          const uid_struct =  (this.ls.getItem('x-udata-rolo') || { uid: '', cuid: ''});
          let uid = uid_struct.cuid || uid_struct.uid;
          //console.log('APP TOKEN UID: ', uid)

          const authReq = serverReq.clone({
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-uid-rolo': uid,
                'x-uname-rolo': uid,
                'x-app-id': authHeader
              })
          })
      
          // Pass on the cloned request instead of the original request.
          return next.handle(authReq).pipe(
            catchError((err: HttpErrorResponse) => {
      
              if (err.status === 401) {
                //this.router.goLogin();
              }
              console.log('INTERCEPTOR_ERROR: ', err);
              return throwError( err );

      
            })
          ).toPromise();
    }
}