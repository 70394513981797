import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IGenericResponse, Backend } from 'src/app/shared/models/GenericResponse'
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStoreService } from './local-store.service';

@Injectable({
  providedIn: 'root'
})
export class StructService {
  
  constructor(
    private http: HttpClient,
    private ls: LocalStoreService) { }

  private struct:Subject<any> = new BehaviorSubject<any>(null);
  private currentClient:Subject<any> = new BehaviorSubject<any>(null);
  private currentLocation:Subject<any> = new BehaviorSubject<any>(null);

  get struct$(){
    return this.struct.asObservable();
  }
  
  get currentClient$(){
    return this.currentClient.asObservable();
  }

  get currentLocation$(){
    return this.currentLocation.asObservable();
  }

  setStruct(data:any){
    this.struct.next(data);
  }

  setCurrentClient(id){
    this.struct
    .subscribe((struct: any[])=>{
      if(struct!=null){        
        const results = struct.filter(f=>f.id == id)        
        results.map(m=>{          
          this.currentClient.next(m);
          this.setCurrentLocation('');
          return m;
        })
        if(results.length>0){
          this.ls.setItem(this.ls.CURRENT_CLIENT, id)
        }else{
          this.ls.remove(this.ls.CURRENT_CLIENT);
        }
      }else{
        this.ls.remove(this.ls.CURRENT_CLIENT);
      } 
    });
  }

  setCurrentLocation(id){
    if(this.struct && this.currentClient){
      this.currentClient
      .subscribe(currentClient=>{
        if(currentClient!=null && currentClient.locations){          
          const results = currentClient.locations.filter(f=>f.id==id || id=='');
          //console.log(`FIND RESULTS WITH ${id}: `, results.length)
          if(results.length>0){            
            this.currentLocation.next(results[0])
          }
        }
      })
    }
  }


  getStruct(): Observable<IGenericResponse> {    
    this.setStruct(null);
    this.ls.remove(this.ls.CURRENT_CLIENT);
    const url = `${Backend.config().api_Url}/companies/clients`
    return this.http.get<IGenericResponse>(url).pipe(
      tap(this.processData)
    );
  }

  private processData =(result: IGenericResponse) => {
    //console.log('THE STRUCT:', result);
     if(result.success){
      if(result.data!=null){
        result.data = result.data.map(c=>{
          if(c.locations){
            c.locations = c.locations.map(l=>{
              const prefix = 'https://api.rolosys.com';
              if(l.logo_url!=null && l.logo_url.indexOf('https://')<0){
                
                l.logo_url = `${prefix}/${l.logo_url}`
              }
              return l;
            })
          }
          return c;
        });
      }
      this.setStruct(result.data);
      if(result.data.length>0){
        //this.currentClient.next(result.data[0]);
        const clientId = this.ls.getItem(this.ls.CURRENT_CLIENT);
        if(clientId!=null){
          this.setCurrentClient(clientId)
        }else{
          this.setCurrentClient(result.data[0].id)
        }
        
      }
    } 
  }

}
