import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IGenericResponse, Backend } from 'src/app/shared/models/GenericResponse'
import { BehaviorSubject, Observable, Subject } from 'rxjs';


export interface IPageInfo {
  id:string,
  view: string,
  root: string
}

@Injectable({
  providedIn: 'root'
})
export class PagesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-uid-rolo': '1ab5464yyd737383829',
      'x-uname-rolo': 'bromero@fortekna.com'
    })
  };

  private page:Subject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {

  }

  get page$(){
    return this.page.asObservable();
  }
  
  setPage(data:any){
    this.page.next(data);
  }

  getPage(data: IPageInfo): Observable<IGenericResponse> {
    
    const url = `${Backend.config().api_Url}/pages`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

  setCompanyData(data:any): Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/pages/update/company`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

  setCompanyColors(data:any): Observable<IGenericResponse> {    
    const url = `${Backend.config().api_Url}/pages/update/colors`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

  setCompanyContact(data:any): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/update/contact`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

  setCompanyMedia( data: any): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/update/media`
    return this.http.post<IGenericResponse>(url, data, this.httpOptions);
  }

  addItem(data: any): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/section/items`
    return this.http.put<IGenericResponse>(url, data, this.httpOptions);
  }

  updatePage(data): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/config/update`
    return this.http.put<IGenericResponse>(url, data, this.httpOptions);
  }

  updateSection(data): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/section/update`
    return this.http.put<IGenericResponse>(url, data, this.httpOptions);
  }

  updateRndConfig(data): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/random_cfg/update`
    return this.http.put<IGenericResponse>(url, data, this.httpOptions);
  }

  setHome(data): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/set_home`
    return this.http.put<IGenericResponse>(url, data, this.httpOptions);
  }

  updateProperty(data): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/set_property`
    return this.http.put<IGenericResponse>(url, data, this.httpOptions);
  }

  updateSorts(data): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/save_sorts`
    return this.http.put<IGenericResponse>(url, data, this.httpOptions);
  }

  addPage(data): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/add-page`
    return this.http.put<IGenericResponse>(url, data, this.httpOptions);
  }

  removePage(data): Observable<IGenericResponse> {
    const url = `${Backend.config().api_Url}/pages/remove-page`
    return this.http.post<IGenericResponse>(url, data);
  }

}
