import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { tap, map, take } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { NotificationsService, NotificationType } from 'angular2-notifications';

import { Socket } from 'ngx-socket-io';
import { Howl } from 'howler';
import { LocalStoreService } from 'src/app/shared/services/local-store.service';

var thisTopNav = null;
@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit, OnDestroy {
  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName = 'loading...';
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  clientSelector = environment.clientSelector;
  sound: Howl;
  channels: string[] = [];

  constructor(
    private sidebarService: SidebarService,
    private authService: AuthService,
    private router: Router,
    private langService: LangService,
    private api: ApiService,
    private socket: Socket,
    private notifications: NotificationsService,
    private ls: LocalStoreService
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    this.listenSocket();
    thisTopNav = this;
  }

  listenSocket() {

    this.socket.on('new_order', function (order) {
      console.log('new order, arguments: ', order)
      thisTopNav.playAudio();
      thisTopNav.notifications.success('New order', 'You have a new order', { theClass: 'success', timeOut: 4000, showProgressBar: true })
      if (Notification) {
        Notification.requestPermission((permission) => {
          var notification = new Notification('New Order',
            {
              body: 'You have a new order',              
              dir: 'auto'
            });
        })
      }
    })
  }

  playAudio() {
    this.sound.play();
  }



  onDarkModeChange(event) {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event) {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang) {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  currentClient: any = null;
  currentLocation: any = null;
  companyStruct: any = null;

  async ngOnInit() {
    //console.log('TOP NAV INIT')
    const audio_src = "../../../assets/media/rolo_incoming01.mp3";

    this.sound = new Howl({
      src: [audio_src]
    });

    if (await this.authService.getUser()) {
      this.displayName = await this.authService.getUser().then((user) => {
        return user.displayName;
      });
    }

    await this.api.Struct.getStruct().toPromise()



    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );



    if (this.clientSelector) {
      this.currentClient = null;
      this.api.Struct.currentClient$
        .subscribe(currentClient => {

          this.currentClient = currentClient;
          //console.log('CURRENT CLIENT: ', this.currentClient);
          if (currentClient != null && currentClient.locations) {
            if (currentClient.locations.length > 0) {
              this.currentLocation = currentClient.locations[0];
              //console.log('CURRENT LOCATION: ', this.currentLocation);
              /*if(!this.channels.includes(this.currentLocation.id)){
                this.channels.push(this.currentLocation.id);
                this.socket.emit('join', { rooms: [this.currentLocation.id] });
              }*/
            }
          }

        })

      this.api.Struct.struct$.
        subscribe(structResult => {
          this.companyStruct = structResult;
        });
    }

    //this.socket.emit('join', { rooms: ['-MJSnon_aLgD7-AYtt02'] });

  }

  changeClient(id) {
    this.api.Struct.setCurrentClient(id);
  }

  setLocation(id) {
    if (this.currentClient != null && this.currentClient.locations) {
      const locations = this.currentClient.locations.filter(f => f.id == id);
      if (locations.length > 0) {
        this.currentLocation = locations[0];
        this.api.Struct.setCurrentLocation(id);
      }else{
        this.currentLocation = null;
      }
    }
  }

  showChangeClientModal() {

  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      true,
      //this.sidebar.selectedMenuHasSubItems
    );
  };

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  };

  onSignOut() {
    this.authService.signOut().subscribe(() => {
      this.ls.remove(this.ls.CURRENT_CLIENT);
      this.ls.remove(this.ls.CURRENT_LOC);
      this.router.navigate(['/user']);
    });
  }

  searchKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event) {
    event.stopPropagation();
  }

  searchClick(event) {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search() {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/pages/miscellaneous/search'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event) {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }
}
