<div class="fixed-subs-background"></div>
<div class="container subscription-container">
    <div class="row">
        <div class="col-md-12 text-center pt-4">
            <img src="/assets/img/brand/logo.png" />
        </div>        
    </div>
    <div class="row h-100">
        <div class="col-10 offset-1 mt-5">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h1 class="font-weight-bold">{{ 'subscription.success.line0' | translate}}</h1>
                            <h6>{{ 'subscription.success.line1' | translate}}</h6><br>
                            <h6>{{ 'subscription.success.line2' | translate}}</h6><br>
                            <h6>{{ 'subscription.success.line3' | translate}}</h6>
                            <a href="https://www.rolosys.com" class="btn btn-primary mt-2" >{{ 'subscription.success.button' | translate}}</a>                           
                            
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-md-12 text-center">
                            <h6>{{ 'subscription.success.line4' | translate}}</h6>
                            <div>
                                <a href="mailto:{{'subscription.success.support.email' | translate}}">{{'subscription.success.support.caption' | translate}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
