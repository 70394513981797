import { environment } from 'src/environments/environment'
export interface IGenericResponse{
    success: boolean,
    id?: string,
    _id?: number,
    message: string,
    data?: any,
    request?: any,
    error?: string,
    code?: number
}

export module Backend {
   let _baseBackendUrl = environment.apiurl;    
   let _baseApiUrl = _baseBackendUrl+'/api'
   console.log('CONNECTED TO: ', _baseApiUrl);
   export function config (){
       return {
           api_Url : _baseApiUrl,
           backend_url: _baseBackendUrl
       }
   }
}