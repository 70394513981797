import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IGenericResponse, Backend } from 'src/app/shared/models/GenericResponse'
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-uid-rolo': '1ab5464yyd737383829',
      'x-uname-rolo': 'bromero@fortekna.com'
    })
  };

  constructor(private http: HttpClient) { }

  getPage(id: string): Observable<IGenericResponse> {
    
    const url = `${Backend.config().api_Url}/accesses/config/${id}`
    return this.http.get<IGenericResponse>(url);
  }

  create(data: any, isLocation:boolean): Observable<IGenericResponse> {    
    const path = isLocation?'location':'company';
    const url = `${Backend.config().api_Url}/accesses/${path}/create`
    return this.http.post<IGenericResponse>(url, data);
  }

  update(data: any, isLocation:boolean): Observable<IGenericResponse> {    
    const path = isLocation?'location':'company';
    const url = `${Backend.config().api_Url}/accesses/${path}/update`
    return this.http.post<IGenericResponse>(url, data);
  }

  getKey(data: any, isLocation: boolean): Observable<IGenericResponse> {    
    const path = isLocation?'location':'company';
    const url = `${Backend.config().api_Url}/accesses/${path}/get`
    return this.http.post<IGenericResponse>(url, data);
  }

  toggle(id, isLocation: boolean): Observable<IGenericResponse> {
    const path = isLocation?'location':'company';

    const url = `${Backend.config().api_Url}/accesses/${path}/toggle/${id}`
    return this.http.get<IGenericResponse>(url);
  }

  toggleParent(id, isLocation: boolean): Observable<IGenericResponse> {
    const path = isLocation?'location':'company';

    const url = `${Backend.config().api_Url}/accesses/${path}/toggle_parent/${id}`
    return this.http.get<IGenericResponse>(url);
  }

}
